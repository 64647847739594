import React, {Component} from 'react'
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import PropTypes from "prop-types";
import {COLORS} from "../util/Util";

class FormDropdown extends Component {
    render() {
        let {value, customStyles, multi = false} = this.props;

        let Tooltip = <div/>;
        if (this.props.tooltip) {
            Tooltip = (
                <i style={{marginLeft: 5}} className="fa fa-info-circle" data-tip={this.props.tooltip}/>
            );
        }

        return (
            <div className="row g-mb-20">
                <div className="col-md-3 align-self-center g-mb-5 g-mb-0--md">
                    <label className="mb-0">{this.props.name}</label>

                    {Tooltip}
                    <ReactTooltip/>
                </div>

                <div className="col-md-9 align-self-center">
                    <div className="form-group mb-0">
                        <Select menuPortalTarget={document.body} className="form-control" styles={{
                            menuPortal: base => ({...base, zIndex: 99999}),
                            menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                            container: (base, {isFocused}) => ({
                                ...base,
                                paddingTop: 3,
                                paddingBottom: 4,
                                borderColor: isFocused ? COLORS.DRIP_MAIN : "#e1eaea"
                            }),
                            control: (base) => ({
                                ...base,
                                border: 0,
                                boxShadow: 0,
                                '&:hover': {
                                    border: 0
                                }
                            }),
                            ...customStyles,
                        }} isMulti={multi} options={this.props.data} value={value} onChange={(vendor) => {
                            if (typeof this.props.onChange !== "undefined") {
                                this.props.onChange(vendor);
                            }
                        }}/>
                    </div>
                </div>
            </div>
        )
    }
}

FormDropdown.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,

    customStyles: PropTypes.object,
    multi: PropTypes.bool
};

export default FormDropdown;