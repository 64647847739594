import React, {Component} from "react"
import Fancy from "../../../modules/fancy/Fancy";

import {FancySelect, FancyText} from "corky"
import {decimalToDollars, request, toDollars, validateFields} from "../../../util/Util";

const FIELDS = {
    NAME: "product-name",
    CATEGORY: "category-name",
    PRICE: "price-name",
};

class CreateProductModal extends Component {
    constructor(props) {
        super(props);

        this.form = {};
    }

    createProduct() {
        let {products} = this.props.partner;
        let form = this.form;

        if (!validateFields(this.fields)) {
            return;
        }

        let name = form[FIELDS.NAME], price = decimalToDollars(form[FIELDS.PRICE]),
            category = form[FIELDS.CATEGORY].value;
        request("products", "POST", {NAME: name, PRICE: price, CATEGORY: category}, (err, id) => {
            if (err) {
                alert("ERROR");
                return;
            }

            this.fancy.close().then(() => {
                this.setState({name: "", price: "", category: null}, () => {
                    this.props.updateProducts([...products, {
                        NAME: name,
                        PRICE: price,
                        CATEGORY_ID: category,
                        CUSTOMIZATIONS: [],
                        ENABLED: true,
                        LOGO: null,
                        ID: id
                    }]);

                    this.props.history.push("/product/" + id);
                });
            });
        });
    }

    getCategoryOptions() {
        let {categories} = this.props.partner;

        return categories.map((item) => ({label: item.NAME, value: item.ID}));
    }

    open() {
        this.fancy.open()
    }

    render() {
        let {location} = this.props.partner;
        this.fields = [];

        return (
            <Fancy name="Create Product" onClick={this.createProduct.bind(this)} ref={(e) => this.fancy = e}
                   buttonText="Create">
                <div className="g-mb-20 row">
                    <FancyText form={this.form} id={FIELDS.NAME} label="Name" half={true}
                               ref={(e) => this.fields.push(e)} required={true}/>

                    <FancySelect form={this.form} id={FIELDS.CATEGORY} label="Category" half={true}
                                 defaultValue={this.getCategoryOptions()[0]}
                                 options={this.getCategoryOptions()} ref={(e) => this.fields.push(e)} required={true}/>
                </div>

                <div className="g-mb-20 row">
                    <FancyText form={this.form} id={FIELDS.PRICE} label="Price" half={true} onChange={(val) => {
                        this.tax.setValue(toDollars(decimalToDollars(val) + Math.round(decimalToDollars(val) * (location.TAX_RATE / 100))))
                    }} ref={(e) => this.fields.push(e)} required={true}/>

                    <FancyText label="Price w/ Tax" half={true} disabled={true} ref={(e) => this.tax = e}/>
                </div>
            </Fancy>
        )
    }
}

export default CreateProductModal;