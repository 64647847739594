import React, {Component} from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {CorkTable} from 'corky'
import CorkButton from "../../../modules/CorkButton";
import PromotionModal from "../modals/PromotionModal";
import 'react-day-picker/lib/style.css';

class PromotionsScreen extends Component {
    render() {
        let {promotions} = this.props.partner;

        return (
            <div>
                <PromotionModal ref={(e) => this.promotionModal = e} {...this.props} />

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                Promotions
                            </h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => this.promotionModal.open()}>
                                Create Promotion
                            </CorkButton>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <CorkTable header={{
                            LOGO: {
                                NAME: "",
                                WIDTH: 5,
                                SORT: "ID"
                            },
                            NAME: {
                                NAME: "Name",
                                WIDTH: 45,
                                TYPE: "String",
                                LINK: "/employee/",
                                LINK_ID: "ID"
                            }
                        }} sort="LOGO" data={promotions} desc={true} title=""/>
                    </div>
                </div>
            </div>
        )
    }
}

export default PromotionsScreen;
