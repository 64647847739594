import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import FormTextBox from "../../../modules/FormTextBox";
import {COLORS, request} from "../../../util/Util";
import Fancy from "../../../modules/fancy/Fancy";
import CorkDrop from "../../../modules/CorkDrop";
import Customization from "../../products/modules/Customization";
import CorkButton from "../../../modules/CorkButton";
import {FancySelect, FancyText} from "corky";
import {Products} from "drip-drop";
import {sendSuccess} from "../../../helpers/NotificationHelper";
import DescriptionScreen from "../../../modules/DescriptionSection";

const FIELDS = {
    NAME: "customization-name",
    INTERNAL_NAME: "customization-internal-name",
    TYPE: "customization-type"
};

class PresetsTab extends Component {
    state = {
        name: "",
        type: 0,
        required: false
    };

    constructor(props) {
        super(props);

        this.form = {};
    }

    remove(id, rawItem) {
        if (!window.confirm("Are you sure you want to remove this customization?")) {
            return;
        }

        request("customization/" + id + "/preset", "DELETE", {}, (err) => {
            if (err) {
                alert("ERROR SAVING ON SERVER");
                return;
            }

            let {presets, rawPresets} = this.props.partner;
            let index = presets.findIndex((item) => item.ID === id);
            presets.splice(index, 1);

            rawPresets = rawPresets.filter((item) => item.CUSTOMIZATION_ID !== id);

            this.props.updateRawPresets(rawPresets);
            this.props.updatePresets(presets);

            sendSuccess(rawItem.NAME + " Removed", `The global modifier ${rawItem.NAME} was removed.`)
        });
    }

    add() {
        let {required} = this.state;

        let isValid = true;
        for (let field of this.fields) {
            if (field === null) {
                continue;
            }

            if (!field.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return;
        }

        let name = this.form[FIELDS.NAME], type = this.form[FIELDS.TYPE].value,
            internal = this.form[FIELDS.INTERNAL_NAME];
        request("preset", "POST", {
            NAME: name,
            TYPE: type,
            REQUIRED: required,
            INTERNAL_NAME: internal ? internal : null
        }, (err, id) => {
            if (err) {
                alert("ERRPR");
                return;
            }

            this.fancy.close().then(() => {
                this.props.updatePresets([
                    ...this.props.partner.presets,
                    {
                        ID: id,
                        NAME: name,
                        REQUIRED: required,
                        TYPE: type,
                        OPTIONS: [],
                        INTERNAL_NAME: internal ? internal : null
                    }
                ]);

                this.setState({name: "", required: false});
            });
        });
    }

    render() {
        const {ID} = this.props.match.params;
        let {presets} = this.props.partner;
        let {required} = this.state;
        let form = this.form;

        this.fields = [];
        let Customizations = presets.filter((item) => {
            return item.TYPE !== Products.Constants.CUSTOMIZATION_TYPES.VARIANT
        }).map((item) => {
            return <Customization item={item} product={{ID: -1}} id={ID} {...this.props}
                                  remove={() => this.remove(item.ID, item)}/>
        });

        return (
            <div className="col-md-12">
                <Fancy name="Create Global Modifier" ref={(e) => this.fancy = e}
                       onClick={this.add.bind(this)}
                >
                    <div className="row g-mb-20">
                        <FancyText label="Name" form={form} id={FIELDS.NAME} required={true}
                                   ref={(e) => this.fields.push(e)} half={true}/>

                        <FancyText label="Internal Name" form={form} id={FIELDS.INTERNAL_NAME} required={false}
                                   ref={(e) => this.fields.push(e)} half={true}/>
                    </div>

                    <div className="g-mb-20">
                        <FancySelect label="Type" options={Products.Constants.CUSTOMIZATION_TYPE_FIELDS} form={form}
                                     id={FIELDS.TYPE} value={Products.Constants.CUSTOMIZATION_TYPE_FIELDS[0]}
                                     ref={(e) => this.fields.push(e)} required={true}/>
                    </div>

                    <div className="form-group d-flex align-items-center justify-content-between g-mb-20">
                        <span>Required</span>
                        <label className="u-check mb-0 g-ml-10">
                            <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                                   type="checkbox" onClick={(e) => this.setState({required: !required})}
                                   checked={required}
                            />

                            <div
                                className="u-check-icon-radio-v8 u-check-icon--md u-check-icon--lightblue-v3">
                                <i className="fa"/>
                            </div>
                        </label>
                    </div>
                </Fancy>
                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">
                                Global Modifiers
                            </h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => {
                                this.form[FIELDS.TYPE] = Products.Constants.CUSTOMIZATION_TYPE_FIELDS[0];
                                this.fancy.open()
                            }}>
                                New Global Modifier
                            </CorkButton>
                        </div>
                    </div>

                    <hr />

                    <DescriptionScreen
                        header="Universal Product Customizations"
                        content="Create global modifiers that sync across multiple products. Global modifiers
                        make it easy to quickly add and edit product customizations & add-ons."
                        image="globalmodifiers.png"/>

                    <div style={{marginTop: 10}}>
                        {Customizations}
                    </div>
                </div>
            </div>
        )
    }
}

export default PresetsTab;