import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import FormTextBox from "../../../modules/FormTextBox";
import {asyncDelay, decimalToDollars, parseIdDict, request, toDollars} from "../../../util/Util";
import FormDropdown from "../../../modules/FormDropdown";
import CorkButton from "../../../modules/CorkButton";
import ButtonRow from "../../../modules/ButtonRow";
import {sendError, sendSuccess} from "../../../helpers/NotificationHelper";
import {Products} from "drip-drop";
import Customization from "../modules/Customization";
import Fancy from "../../../modules/fancy/Fancy";
import {FancySelect} from "corky";
import CreateCustomizationModal from "../modules/CreateCustomizationModal";
import FormCheckbox from "../../../modules/FormCheckbox";

const FANCY_FIELDS = {
    ADD_PRESET: "add-preset-preset"
};

class EditTab extends Component {
    state = {
        product: null,
        fields: null
    };

    constructor(props) {
        super(props);

        this.addPresetForm = {};
    }

    componentWillMount() {
        const {ID} = this.props.match.params;
        let {categories} = this.props.partner;
        categories = parseIdDict(categories);

        let oldProduct = this.props.partner.products.find((item) => item.ID === parseInt(ID));
        oldProduct = JSON.parse(JSON.stringify(oldProduct));
        oldProduct.CATEGORY_CHANGE = {label: categories[oldProduct.CATEGORY_ID].NAME, value: oldProduct.CATEGORY_ID};
        oldProduct.PRICE_DOLLARS = toDollars(oldProduct.PRICE);
        this.setState({product: oldProduct});
    }

    edit() {
        const {ID} = this.props.match.params;
        let {product} = this.state;

        if (product.DESCRIPTION !== null && product.DESCRIPTION.length === 0) {
            product.DESCRIPTION = null;
        }

        let startTime = new Date().getTime();
        this.saveButton.startLoading();

        try {
            product.PRICE = decimalToDollars(product.PRICE_DOLLARS);
        } catch (e) {
            return sendError("Parsing Error", "Error trying to parse product price");
        }

        product.CATEGORY_ID = product.CATEGORY_CHANGE.value;
        request("products/" + ID, "PATCH", product).then(async () => {
            await asyncDelay(startTime);

            let {products} = this.props.partner;
            let index = products.findIndex((item) => item.ID === parseInt(ID));
            products.splice(index, 1, product);

            this.setState({product});
            this.props.updateProducts(products);
            sendSuccess(product.NAME + " Updated", product.NAME + " has been saved.")
        }).catch(() => {
            sendError("Internal Error", "There was a problem trying to update this product")
        })
    }

    remove(id, rawItem) {
        const {ID} = this.props.match.params;
        if (!window.confirm("Are you sure you want to remove this customization?")) {
            return;
        }

        let {products, rawPresets} = this.props.partner;
        let productIndex = products.findIndex((item) => item.ID === parseInt(ID));
        let customizations = products[productIndex].CUSTOMIZATIONS;
        let index = customizations.findIndex((item) => item.ID === id);

        if (index === -1) {
            request(`preset/${id}/${parseInt(ID)}/remove`, "POST", {}, (err) => {
                if (err) {
                    alert("ERROR DELETING ON SERVER");
                    return;
                }

                let presetIndex = rawPresets.findIndex((item) => item.CUSTOMIZATION_ID === id && item.PRODUCT_ID === parseInt(ID));
                rawPresets.splice(presetIndex, 1);

                this.props.updateRawPresets(rawPresets);

                sendSuccess(rawItem.NAME + " Removed", rawItem.NAME + " was removed as a variant.");
            });
        } else {
            request("customization/" + id, "DELETE", {}, (err) => {
                if (err) {
                    alert("ERROR SAVING ON SERVER");
                    return;
                }

                customizations.splice(index, 1);
                products.splice(productIndex, 1, {
                    ...products[productIndex],
                    CUSTOMIZATIONS: customizations
                });

                this.props.updateProducts(products);

                sendSuccess(rawItem.NAME + " Removed", rawItem.NAME + " was removed as a variant.");
            });
        }
    }

    addVariant() {
        const {ID} = this.props.match.params;
        let form = this.addPresetForm;

        let preset = form[FANCY_FIELDS.ADD_PRESET].value;
        request("preset/" + preset + "/product/" + ID, "POST", {}, (err, payload) => {
            if (err) {
                alert("Error adding preset");
                return;
            }

            this.presetFancy.close().then(() => {
                this.props.updateRawPresets([...this.props.partner.rawPresets, {
                    ID: payload,
                    PRODUCT_ID: parseInt(ID),
                    CUSTOMIZATION_ID: preset
                }]);
            });
        });
    }

    _getVariantCustomizations() {
        const {ID} = this.props.match.params;
        let {products} = this.props.partner;

        let product = JSON.parse(JSON.stringify(products.find((item) => parseInt(ID) === item.ID)));
        let rawPresets = this.props.partner.rawPresets.filter((item) => item.PRODUCT_ID === parseInt(ID));

        for (let rawPreset of rawPresets) {
            let customization = this.props.partner.presets.find((item) => item.ID === rawPreset.CUSTOMIZATION_ID);
            customization = JSON.parse(JSON.stringify(customization));
            customization.SEQ = rawPreset.SEQ;
            customization.IS_PRESET = true;

            product.CUSTOMIZATIONS.push(customization);
        }

        return product.CUSTOMIZATIONS.filter((item) => {
            return item.TYPE === Products.Constants.CUSTOMIZATION_TYPES.VARIANT
        });
    }

    updateProduct(product) {
        let products = this.props.partner.products;
        let index = products.findIndex((item) => item.ID === product.ID);

        products.splice(index, 1, product);

        this.props.updateProducts(products);
    }

    renderVariants() {
        let {products} = this.props.partner;
        const {ID} = this.props.match.params;
        let customizations = this._getVariantCustomizations();

        let product = JSON.parse(JSON.stringify(products.find((item) => parseInt(ID) === item.ID)));
        if (customizations.length === 0) {
            return;
        }

        let item = customizations[0];
        return (
            <div>
                <header>
                    <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                        Variants
                    </h2>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>
                </header>

                <Customization item={item} product={product} id={ID} {...this.props} isPreset={item.IS_PRESET}
                               updateProduct={() => this.updateProduct.bind(this)} variant={true}
                               remove={() => this.remove(item.ID, item)}/>
            </div>
        )
    }

    renderVariantButton() {
        let customizations = this._getVariantCustomizations();
        if (customizations.length > 0) {
            return
        }

        return (
            <div className="row" style={{marginLeft: 0}}>
                <CorkButton type={CorkButton.TYPES.OUTLINE} marginLeft ref={(e) => this.variableButton = e}
                            onClick={() => this.createCustomization.open(true)}>
                    Add Variants
                </CorkButton>

                <CorkButton type={CorkButton.TYPES.OUTLINE} marginLeft ref={(e) => this.globalButton = e}
                            onClick={() => this.presetFancy.open()}>
                    Add Global Variants
                </CorkButton>
            </div>
        );
    }

    renderFancies() {
        const {ID} = this.props.match.params;
        let {presets} = this.props.partner;
        this.addPresetRefs = [];

        let product = JSON.parse(JSON.stringify(this.props.partner.products.find((item) => parseInt(ID) === item.ID)));
        let rawPresets = this.props.partner.rawPresets.filter((item) => item.PRODUCT_ID === parseInt(ID));

        for (let rawPreset of rawPresets) {
            let customization = this.props.partner.presets.find((item) => item.ID === rawPreset.CUSTOMIZATION_ID);
            customization = JSON.parse(JSON.stringify(customization));
            customization.SEQ = rawPreset.SEQ;
            customization.IS_PRESET = true;

            product.CUSTOMIZATIONS.push(customization);
        }

        product.CUSTOMIZATIONS.sort((a, b) => (a.SEQ === 0 ? 1000000 : a.SEQ) - (b.SEQ === 0 ? 10000 : b.SEQ));

        let addPresets = presets.filter((item) => rawPresets.findIndex((_item) => _item.CUSTOMIZATION_ID === item.ID) === -1);
        let addOptions = addPresets.filter((item) => {
            return item.TYPE === Products.Constants.CUSTOMIZATION_TYPES.VARIANT
        }).map((item) => {
            return {value: item.ID, label: item.INTERNAL_NAME !== null ? item.INTERNAL_NAME : item.NAME}
        });

        return (
            <Fancy name="Add Global Variant" ref={(e) => this.presetFancy = e} buttonText="Add"
                   onClick={this.addVariant.bind(this)}
            >
                <FancySelect options={addOptions} form={this.addPresetForm} label="Select a Global Variant:"
                             ref={(e) => this.addPresetRefs.push(e)} id={FANCY_FIELDS.ADD_PRESET}
                             value={addOptions.length > 0 ? addOptions[0] : null}/>
            </Fancy>
        )
    }

    render() {
        let {product} = this.state;
        let {categories, location} = this.props.partner;

        return (
            <div className="col-md-9">
                {this.renderFancies()}
                <CreateCustomizationModal ref={(e) => this.createCustomization = e} product={product} {...this.props} />

                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <header>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                General information
                            </h2>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>
                        </header>

                        <FormItem name="Name" value={product.NAME}
                                  onChange={(txt) => this.setState({product: {...product, NAME: txt}})}/>

                        <FormDropdown name="Category" value={product.CATEGORY_CHANGE}
                                      data={categories.map((item) => {
                                          return {label: item.NAME, value: item.ID}
                                      })} onChange={(txt) => this.setState({
                            product: {
                                ...product,
                                CATEGORY_CHANGE: txt
                            }
                        })}/>

                        <FormTextBox name="Description" value={product.DESCRIPTION}
                                     onChange={(txt) => this.setState({
                                         product: {
                                             ...product,
                                             DESCRIPTION: txt
                                         }
                                     })}/>

                        <FormItem name="Price"
                                  value={this._getVariantCustomizations().length > 0 ? "View Variants" : product.PRICE_DOLLARS}
                                  disabled={this._getVariantCustomizations().length > 0}
                                  onChange={(txt) => this.setState({
                                      product: {
                                          ...product,
                                          PRICE_DOLLARS: txt
                                      }
                                  })}/>

                        <FormItem name="Price w/ Tax"
                                  value={
                                      this._getVariantCustomizations().length > 0 ? "View Variants" :
                                          "$" + toDollars(decimalToDollars(product.PRICE_DOLLARS) + Math.round(decimalToDollars(product.PRICE_DOLLARS) * (location.TAX_RATE / 100)))}
                                  disabled={true}/>

                        <FormCheckbox label="Disable tax for this product"
                                      value={product.TAX_DISABLED} onChange={(txt) => this.setState({
                            product: {...product, TAX_DISABLED: !product.TAX_DISABLED}
                        })}/>

                        <FormCheckbox label="Require a day in advanced notice" onChange={() => this.setState({
                            product: {...product, NEXT_DAY: !product.NEXT_DAY}
                        })} value={product.NEXT_DAY}/>

                        <FormCheckbox label="Product will have variable pricing (input price at checkout)"
                                      value={product.VARIABLE} onChange={(txt) => this.setState({
                            product: {...product, VARIABLE: !product.VARIABLE}
                        })}/>

                        <FormCheckbox label="One click add to cart (insta add to cart at checkout)"
                                      value={product.ONE_CLICK} onChange={(txt) => this.setState({
                            product: {...product, ONE_CLICK: !product.ONE_CLICK}
                        })}/>

                        {this.renderVariants()}

                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                        <ButtonRow>
                            <CorkButton ref={(e) => this.saveButton = e} onClick={this.edit.bind(this)}>
                                Save Changes
                            </CorkButton>

                            {this.renderVariantButton()}
                        </ButtonRow>
                    </form>
                </div>
            </div>
        )
    }
}


export default EditTab;