import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import {request, toDollars} from "../../../util/Util";
import 'react-day-picker/lib/style.css';
import {CorkTable} from "corky";
import {bindActionCreators} from "redux";
import Actions from "../../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

let moment = require("moment");

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class PayoutTab extends Component {
    render() {
        let {timesheets, employees} = this.props.partner;
        const {PAYOUT, ID} = this.props.match.params;

        let employee = employees.find((item) => item.ID === parseInt(ID));
        if (!employee) {
            return (
                <div />
            )
        }

        let Payouts = timesheets.filter((item) => {
            return item.EMPLOYEE_ID === employee.ID && item.PAYOUT_ID === parseInt(PAYOUT)
        }).map((timesheet) => {
            let hours = ((timesheet.DATE_END - timesheet.DATE_START) / (1000 * 60 * 60)).toFixed(1);

            return {
                DATE: moment(timesheet.DATE_START).format("MM/DD/YY"),
                HOURS: hours,
                RATE: "$" + toDollars(timesheet.RATE),
                TOTAL: "$" + toDollars(hours * timesheet.RATE),
                PAYOUT_ID: timesheet.PAYOUT_ID,
                PAYROLL_ID: timesheet.PAYROLL_ID
            }
        });

        return (
            <div className="col-md-9">
                <div className="media">
                    <div className="d-flex align-self-center">
                        <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Payouts</h1>
                    </div>
                </div>

                <hr />

                <CorkTable header={{
                    DATE: {
                        NAME: "Date",
                        WIDTH: 10,
                    },
                    HOURS: {
                        NAME: "Hours",
                        WIDTH: 10,
                    },
                    RATE: {
                        NAME: "Rate",
                        WIDTH: 15
                    },
                    TOTAL: {
                        NAME: "Wage",
                        WIDTH: 15
                    }
                }} sort="DATE" data={Payouts} desc={true} title=""/>
            </div>
        );
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PayoutTab));