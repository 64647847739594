import React, {Component} from 'react'
import {BrowserRouter, Redirect, Route, Switch, withRouter} from 'react-router-dom'
import Navbar from './modules/Navbar'
import Header from './modules/Header'
import Bar from "./components/dashboard/DashboardScreen";
import {applyMiddleware, bindActionCreators, combineReducers, compose, createStore} from 'redux'
import {connect, Provider} from 'react-redux'
import Actions from './actions/index'
import {getShortURL, request} from './util/Util'

import {render} from 'react-dom'
import thunk from 'redux-thunk';
import userReducers from './reducers/user'
import partnerReducers from './reducers/partner'
import notificationReducers from './reducers/notification'
import ReduxToastr, {reducer as toastrReducer} from 'react-redux-toastr'
import io from 'socket.io-client';
import Joyride from 'react-joyride';
import {RingLoader} from "react-spinners";

import LoginScreen from "./components/login/LoginScreen";
import FourZeroFour from "./components/FourZeroFour";
import ProductsScreen from "./components/products/ProductsScreen";
import ProductScreen from "./components/products/ProductScreen";
import CategoriesScreen from "./components/products/CategoriesScreen";
import FeedScreen from "./components/feed/FeedScreen";
import ShopScreen from "./components/shop/ShopScreen";
import PatronsScreen from "./components/patrons/PatronsScreen";
import PatronScreen from "./components/patrons/PatronScreen";
import OrdersScreen from "./components/orders/OrdersScreen";
import OrderScreen from "./components/orders/OrderScreen";
import NewsScreen from "./components/news/NewsScreen";
import PostScreen from "./components/news/PostScreen";
import OrderSummary from "./components/reporting/OrderSummary";
import SalesSummary from "./components/reporting/SalesSummary";
import TipSummary from "./components/reporting/TipSummary";
import TutorialScreen from "./components/onboarding/TutorialScreen";
import PresetsTab from "./components/shop/tabs/PresetsTab";
import EmployeesScreen from "./components/employees/EmployeesScreen";
import SchedulingScreen from "./components/scheduling/SchedulingScreen";
import EmployeeScreen from "./components/employees/EmployeeScreen";
import VendorsScreen from "./components/accounting/VendorsScreen";
import InvoicesScreen from "./components/accounting/invoices/InvoicesScreen";
import InvoiceScreen from "./components/accounting/invoices/InvoiceScreen";
import Intercom from 'react-intercom';
import TimesheetsScreen from "./components/operations/TimesheetsScreen";
import PayrollScreen from "./components/accounting/PayrollScreen";
import PayrunScreen from "./components/accounting/PayrunScreen";
import HoursScreen from "./components/shop/HoursScreen";
import CategoryScreen from "./components/products/CategoryScreen";
import LetterScreen from "./components/letters/LetterScreen";
import LettersScreen from "./components/letters/LettersScreen";
import ComingSoon from "./components/ComingSoon";
import ChecklistsScreen from "./components/checklists/ChecklistsScreen";
import ChecklistScreen from "./components/checklists/ChecklistScreen";
import PaymentsReport from "./components/reporting/PaymentsReport";
import ProductsReport from "./components/reporting/ProductsReport";
import CategoriesReport from "./components/reporting/CategoriesReport";
import TaxesReport from "./components/reporting/TaxesReport";
import SettingsScreen from "./components/settings/SettingsScreen";
import RolesScreen from "./components/roles/RolesScreen";
import FormsScreen from "./components/team/FormsScreen";
import TicketReport from "./components/reporting/TicketReport";
import RetentionScreen from "./components/marketing/RetentionScreen";
import LoyaltyScreen from "./components/marketing/loyalty/LoyaltyScreen";
import DiscountsScreen from "./components/products/DiscountsScreen";
import BankingContainer from "./components/banking/BankingContainer";

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import VariantScreen from "./components/products/VariantScreen";
import SetupScreen from "./components/setup/SetupScreen";
import DayScheduling from "./components/scheduling/types/DayScheduling";
import PublishScreen from "./components/scheduling/PublishScreen";
import OtherPaymentsScreen from "./components/products/OtherPaymentsScreen";
import DrawerSummary from "./components/reporting/DrawerSummary";
import DrawerScreen from "./components/reporting/DrawerScreen";
import RoleScreen from "./components/roles/RoleScreen";
import PromotionsScreen from "./components/marketing/promotion/PromotionsScreen";
import CouponsScreen from "./components/marketing/coupons/CouponsScreen";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

const TEST = [
    {
        target: 'body',
        content: 'This is my awesome feature!',
    },
    {
        target: '.test_breakdog',
        content: 'This another awesome feature!',
    }
];

Date.prototype.getISODay = function () {
    let day = this.getDay();

    day -= 1;

    return day === -1 ? 6 : day;
};

class PartnerComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {logged: false, test: false};
    }

    componentWillMount() {
        if (typeof window.localStorage['ADMINS'] !== 'undefined') {
            this.props.updateOverseer(JSON.parse(window.localStorage['ADMINS']));
        }

        let url = "dashboard/valid?partner=true";
        let currentLocation = localStorage.getItem("CURRENT_LOCATION");
        if (currentLocation !== null) {
            url += "&location=" + currentLocation;
        }

        let {reporting} = this.props.partner;
        request(url, "GET", null, (err, payload) => {
            if (err) {
                localStorage.removeItem("CURRENT_LOCATION");
                return this.props.history.push('/login');
            }

            let {account, admin, locations, partner, permissions} = payload;
            let {rawLocation: location, invoices, customers: vendors, employeePreferences} = admin;
            let {
                categories, products, rawPresets, hours, closings, discounts, info, timesheets, settings, shifts,
                patrons, presets, news, redeems, orders, tips, otherPayments, promotions, coupons
            } = partner;

            account.PERMISSIONS = permissions;

            let newPayload = {
                account, location, employees: admin.members, roles: admin.roles, categories, presets, news, redeems,
                products, rawPresets, hours, closings, discounts, info, timesheets, settings, shifts, patrons, orders,
                invoices, vendors, tips, otherPayments, employeePreferences, promotions, coupons
            }

            localStorage.setItem("CURRENT_LOCATION", location.ID);


            this.props.updateReporting({...reporting, locations: [{label: location.NAME, value: location.ID}]});
            this.props.updateOverseer(locations);
            this.props.updatePartner(newPayload);
            this.props.updateLoading(false);
            this.setState({logged: true});

            // this.loadIntercom(account);

            // let location = localStorage.getItem("CURRENT_LOCATION");
            // if (location === null) {
            //     localStorage.setItem("CURRENT_LOCATION", payload[0].location.ID);
            //     this.props.updatePartner(payload.find((item) => item.location.ID ===
            // parseInt(payload[0].location.ID))); } else { let target = payload.find((item) => item.location.ID ===
            // parseInt(location));  if (typeof target !== "undefined") { this.props.updatePartner(target); return; }
            // let actualLocation = payload.find((item) => item.location.ID === parseInt(payload[0].location.ID));
            //  localStorage.setItem("CURRENT_LOCATION",
            // payload[0].location.ID); this.props.updatePartner(actualLocation); }
        });
    }

    renderIntercom() {
        let {account} = this.props.partner;

        if (account === null) {
            return <div/>
        }

        const user = {
            name: account.FULL_NAME,
            email: account.EMAIL
        };

        return (
            <Intercom appID="xu7y7yrc" {...user} />
        );
    }

    componentDidMount() {
        document.title = "Drip Partner";

        // let socket = io.connect(getShortURL());
        // socket.on('connect', function () {
        //     console.log(getShortURL());
        //
        //     socket.emit("token", {
        //         TOKEN: window.localStorage['TOKEN'],
        //         LOCATION: window.localStorage.getItem("CURRENT_LOCATION")
        //     });
        // });
        //
        // socket.on("checkout", (payload) => {
        //     this.props.updateOrders([...this.props.partner.orders, payload]);
        // });

        // checkOnboard.call(this);
    }

    render() {
        if (!this.state.logged) {
            return <LoadingScreen {...this.props}/>
        }

        return (
            <div className="app">
                <LoadingScreen {...this.props}/>

                <ReduxToastr timeOut={4000}
                             newestOnTop={true} showCloseButton={false}
                             preventDuplicates
                             getState={(state) => state.toastr}
                             position="top-right"
                             transitionIn="fadeIn"
                             transitionOut="fadeOut"
                             progressBar
                             closeOnToastrClick/>

                {/* Header */}
                <Header {...this.props} />
                {/* End Header */}
                <main className="container-fluid px-0 g-pt-65">
                    <div className="row no-gutters g-pos-rel g-overflow-x-hidden">
                        <Joyride
                            run={this.state.test}
                            steps={TEST}
                            continuous
                            scrollToFirstStep
                            showProgress
                            showSkipButton
                            styles={{
                                options: {
                                    zIndex: 10000
                                }
                            }}
                        />

                        {this.renderIntercom()}

                        {/* Sidebar Nav */}
                        <Navbar {...this.props} />

                        <div className="col g-ml-45 g-ml-0--lg g-pb-65--md g-overflow-hidden">
                            <Switch>
                                <Route exact path='/' component={() => <Bar {...this.props} />}/>
                                <Route path='/order/:ID' component={OrderScreen}/>
                                <Route path='/category/:ID' component={CategoryScreen}/>
                                <Route path='/patron/:ID' component={PatronScreen}/>
                                <Route path='/product/:ID' component={ProductScreen}/>
                                <Route path='/employee/:ID' component={EmployeeScreen}/>
                                <Route path='/role/:ID' component={RoleScreen}/>
                                <Route path='/letter/:ID' component={LetterScreen}/>
                                <Route path='/invoice/:ID' component={InvoiceScreen}/>
                                <Route path='/post/:ID' component={PostScreen}/>
                                <Route path='/payrun/:ID' component={PayrunScreen}/>
                                <Route path='/checklist/:ID' component={ChecklistScreen}/>
                                <Route path='/settings/:ID' component={SettingsScreen}/>
                                <Route path='/loyalty/:ID' component={LoyaltyScreen}/>
                                <Route path='/banking' component={BankingContainer}/>
                                <Route path='/shop' component={() => <ShopScreen {...this.props} />}/>
                                <Route exact path='/feed' component={() => <FeedScreen {...this.props} />}/>
                                <Route exact path='/forms' component={() => <FormsScreen {...this.props} />}/>
                                <Route exact path='/products' component={() => <ProductsScreen {...this.props} />}/>
                                <Route exact path='/employees' component={() => <EmployeesScreen {...this.props} />}/>
                                <Route exact path='/timesheets' component={() => <TimesheetsScreen {...this.props} />}/>
                                <Route exact path='/promotions' component={() => <PromotionsScreen {...this.props} />}/>
                                <Route exact path='/coupons' component={() => <CouponsScreen {...this.props} />}/>

                                <Route exact path='/scheduling' component={SchedulingScreen}/>
                                <Route exact path='/scheduling/day' component={DayScheduling}/>
                                <Route exact path='/publish/:WINDOW' component={PublishScreen}/>

                                <Route exact path='/categories' component={() => <CategoriesScreen {...this.props} />}/>
                                <Route exact path='/discounts' component={() => <DiscountsScreen {...this.props} />}/>
                                <Route exact path='/presets' component={() => <PresetsTab {...this.props} />}/>
                                <Route exact path='/variants' component={() => <VariantScreen {...this.props} />}/>
                                <Route exact path='/patrons' component={() => <PatronsScreen {...this.props} />}/>
                                <Route exact path='/orders' component={() => <OrdersScreen {...this.props} />}/>
                                <Route exact path='/letters' component={() => <LettersScreen {...this.props} />}/>
                                <Route exact path='/checklists' component={() => <ChecklistsScreen {...this.props} />}/>
                                <Route exact path='/news' component={() => <NewsScreen {...this.props} />}/>
                                <Route exact path='/roles' component={() => <RolesScreen {...this.props} />}/>
                                <Route exact path='/other' component={() => <OtherPaymentsScreen {...this.props} />}/>
                                <Route exact path='/report/orders' component={() => <OrderSummary {...this.props} />}/>
                                <Route exact path='/report/sales' component={() => <SalesSummary {...this.props} />}/>
                                <Route exact path='/report/drawer' component={() => <DrawerSummary {...this.props} />}/>
                                <Route exact path='/report/drawer/:ID' component={DrawerScreen}/>
                                <Route exact path='/report/tickets' component={() => <TicketReport {...this.props} />}/>
                                <Route exact path='/report/payments'
                                       component={() => <PaymentsReport {...this.props} />}/>
                                <Route exact path='/report/products'
                                       component={() => <ProductsReport {...this.props} />}/>
                                <Route exact path='/report/categories'
                                       component={() => <CategoriesReport {...this.props} />}/>
                                <Route exact path='/report/taxes' component={() => <TaxesReport {...this.props} />}/>
                                <Route exact path='/report/tips' component={() => <TipSummary {...this.props} />}/>
                                <Route exact path='/onboarding/tutorial'
                                       component={() => <TutorialScreen {...this.props} />}/>

                                <Route exact path='/vendors' component={() => <VendorsScreen {...this.props} />}/>
                                <Route exact path='/invoices' component={() => <InvoicesScreen {...this.props} />}/>
                                <Route exact path='/payroll' component={() => <PayrollScreen {...this.props} />}/>
                                <Route exact path='/retention' component={() => <RetentionScreen {...this.props} />}/>

                                <Route exact path='/hours' component={() => <HoursScreen {...this.props} />}/>
                                <Route exact path="/404" component={FourZeroFour}/>
                                <Route exact path="/soon/:ID" component={ComingSoon}/>

                                <Redirect from='*' to='/404'/>
                            </Switch>

                            {/* Footer */}
                            {/*<footer id="footer"
                             className="u-footer--bottom-sticky g-bg-white g-color-gray-dark-v6 g-brd-top g-brd-gray-light-v7 g-pa-20">
                             <div className="row align-items-center">
                             Footer Nav
                             <div className="col-md-4 g-mb-10 g-mb-0--md">
                             <ul className="list-inline text-center text-md-left mb-0">
                             <li className="list-inline-item">
                             <a className="g-color-gray-dark-v6 g-color-lightblue-v3--hover"
                             href="https://drip.is/contact" target="_blank">Contact Us</a>
                             </li>
                             </ul>
                             </div>
                             End Footer Nav
                             Footer Socials
                             <div className="col-md-4 g-mb-10 g-mb-0--md">
                             <ul className="list-inline g-font-size-16 text-center mb-0">
                             <li className="list-inline-item g-mx-10">
                             <a href="https://www.facebook.com/corkdrinks" target="_blank"
                             rel="noopener noreferrer"
                             className="g-color-facebook g-color-lightblue-v3--hover">
                             <i className="fa fa-facebook-square"/>
                             </a>
                             </li>
                             <li className="list-inline-item g-mx-10">
                             <a href="http://instagram.com/corkdrinks" target="_blank"
                             rel="noopener noreferrer"
                             className="g-color-black g-color-lightblue-v3--hover">
                             <i className="fa fa-instagram"/>
                             </a>
                             </li>
                             <li className="list-inline-item g-mx-10">
                             <a href="https://twitter.com/CorkDrinks" target="_blank"
                             rel="noopener noreferrer"
                             className="g-color-twitter g-color-lightblue-v3--hover">
                             <i className="fa fa-twitter"/>
                             </a>
                             </li>
                             </ul>
                             </div>
                             <div className="col-md-4 text-center text-md-right">
                             <small className="d-block g-font-size-default">© 2020 Cork & Paw, LLC. All Rights
                             Reserved.
                             </small>
                             </div>
                             </div>
                             </footer>*/}
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const LOADING_TYPES = [
    // {WIDTH: 55, CLIP: PacmanLoader},
    // {WIDTH: 75, CLIP: BounceLoader},
    // {WIDTH: 50, CLIP: GridLoader},
    // {WIDTH: 100, CLIP: HashLoader},
    {WIDTH: 125, CLIP: RingLoader}
];

class LoadingScreen extends Component {
    render() {
        let {loading} = this.props.partner;

        if (!loading) {
            return <div/>
        }

        let Type = LOADING_TYPES[Math.floor(Math.random() * LOADING_TYPES.length)];
        let DataType = Type.CLIP;
        let Width = Type.WIDTH;

        return (
            <div>
                <div style={{
                    position: "fixed",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    zIndex: 4999
                }}/>

                <div style={{
                    position: "fixed",
                    left: "calc(50% - " + (Width / 2) + "px)",
                    top: "calc(50% - " + (Width / 2) + "px)",
                    zIndex: 5000
                }}>
                    <DataType
                        sizeUnit={"px"}
                        size={Width}
                        color={'#123abc'}
                        loading={true}
                    />
                </div>
            </div>
        )
    }
}

let PartnerHandler = withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerComponent));

let store = createStore(combineReducers({
    user: userReducers,
    partner: partnerReducers,
    notifications: notificationReducers,
    toastr: toastrReducer
}), compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f));

render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={LoginScreen}/>
                <Route path="/setup" component={SetupScreen}/>
                <Route exact path="/*" component={PartnerHandler}/>
            </Switch>
        </BrowserRouter>
    </Provider>
    ,
    document.getElementById('root')
);
