import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import {toDollars} from "../../../util/Util";

class TransactionTab extends Component {
    render() {
        const {ID} = this.props.match.params;
        let {order} = this.props;

        let tip = this.props.partner.tips.find((item) => item.ORDER_ID === order.ID);

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Transactions
                        </h2>
                        <p style={{marginTop: 5}}>
                            These are the transaction ids that will show up in your stripe dashboard
                        </p>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <FormItem disabled={true} name="Order"
                              value={order.TRANSFER_ID === null ? "Unknown" : order.TRANSFER_ID}/>
                    <FormItem disabled={true} name="Tip" value={tip ? tip.TRANSFER_ID : "None"}/>
                    <FormItem disabled={true} name="Refund"
                              value={order.REFUND_ID === null ? "None" : order.REFUND_ID}/>
                </div>
            </div>
        )
    }
}

export default TransactionTab;