import React, {Component} from 'react'
import Double from "../../../modules/Double";
import {request} from "../../../util/Util";
import {Calendar, momentLocalizer} from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CorkButton from "../../../modules/CorkButton";
import Select from "react-select";
import {EMPLOYEE_TYPES, MOBILE_CLOSE_OFFSETS, MOBILE_OPEN_OFFSETS} from "../../../util/Constants";
import FormDropdown from "../../../modules/FormDropdown";
import {FancyDate} from "corky";
import DayPickerInput from "react-day-picker/DayPickerInput";

let moment = require("moment");
let chrono = require('chrono-node');

const localizer = momentLocalizer(moment);


class HoursTab extends Component {
    state = {
        openOffset: null,
        closeOffset: null,

        day: new Date(),
        open: "",
        close: "",

        hours: {
            SUNDAY_OPEN: "",
            SUNDAY_CLOSE: "",
            MONDAY_OPEN: "",
            MONDAY_CLOSE: "",
            TUESDAY_OPEN: "",
            TUESDAY_CLOSE: "",
            WEDNESDAY_OPEN: "",
            WEDNESDAY_CLOSE: "",
            THURSDAY_OPEN: "",
            THURSDAY_CLOSE: "",
            FRIDAY_OPEN: "",
            FRIDAY_CLOSE: "",
            SATURDAY_OPEN: "",
            SATURDAY_CLOSE: ""
        },
    };

    componentDidMount() {
        let {settings} = this.props.partner;
        if (this.props.partner.hours === null) {
            return;
        }

        let clone = JSON.parse(JSON.stringify(this.props.partner.hours));
        for (let key of Object.keys(clone)) {
            if (key === "ID" || key === "LOCATION_ID") {
                continue;
            }

            let option = clone[key];
            if (option === null) {
                clone[key] = "CLOSED";
                continue;
            }

            clone[key] = moment(new Date(option)).format('h:mm a');
        }

        let settingsMobileOpen = parseInt(settings.MOBILE_OPEN_OFFSET);
        let settingsMobileClose = parseInt(settings.MOBILE_CLOSE_OFFSET);
        let params = {hours: clone};

        let mobileParam = MOBILE_OPEN_OFFSETS.findIndex((item) => item.value === settingsMobileOpen);
        if (mobileParam !== -1) {
            params.openOffset = MOBILE_OPEN_OFFSETS[mobileParam];
        } else {
            params.openOffset = MOBILE_OPEN_OFFSETS[MOBILE_OPEN_OFFSETS.length - 1];
        }

        mobileParam = MOBILE_CLOSE_OFFSETS.findIndex((item) => item.value === settingsMobileClose);
        if (mobileParam !== -1) {
            params.closeOffset = MOBILE_CLOSE_OFFSETS[mobileParam];
        } else {
            params.closeOffset = MOBILE_CLOSE_OFFSETS[MOBILE_CLOSE_OFFSETS.length - 1];
        }

        this.setState(params);
    }

    save() {
        let {settings} = this.props.partner;
        let {hours, openOffset, closeOffset} = this.state;

        let clone = JSON.parse(JSON.stringify(hours));
        for (let key of Object.keys(clone)) {
            if (key === "ID" || key === "LOCATION_ID") {
                continue;
            }

            let option = clone[key];
            if (option.trim() === "") {
                alert("Please fill out every input with either a time or CLOSED");
                return;
            }

            if (option.toLowerCase() === "closed") {
                clone[key] = null;
            } else {
                try {
                    clone[key] = chrono.parseDate(option).getTime();
                } catch (e) {
                    alert("We are having trouble parsing one of your times. Please make sure it's a time using the " +
                        "format 12:00 PM or if the shop is closed have the time be CLOSED for both open and closed");

                    return;
                }
            }
        }

        request("hours", "POST", clone).then(() => {
            this.props.updateHours(clone);

            alert("Hours has been updated");
        }).catch(() => {
            alert("There was a problem saving your changes");
        });

        if (parseInt(settings.MOBILE_OPEN_OFFSET) !== openOffset.value || parseInt(settings.MOBILE_CLOSE_OFFSET) !== closeOffset.value) {
            request("partner/mobile/hours", "POST", {
                OPEN: "" + openOffset.value,
                CLOSE: "" + closeOffset.value
            }).then(() => {
                this.props.updateSettings({
                    ...settings,
                    MOBILE_OPEN_OFFSET: openOffset.value,
                    MOBILE_CLOSE_OFFSET: closeOffset.value
                });
            })
        }
    }

    addClosing() {
        let {day, open, close} = this.state;

        if (open.trim() === "" || close.trim() === "") {
            return alert("Please fill out every input with either a time or CLOSED");
        }

        let DATE_OPENING = null, DATE_CLOSING = null;
        if (open.toLowerCase() !== "closed") {
            try {
                DATE_OPENING = chrono.parseDate(open + " " + day.toDateString());
            } catch (e) {
                return alert("We are having trouble parsing one of your times. Please make sure it's a time using the " +
                    "format 12:00 PM or if the shop is closed have the time be CLOSED for both open and closed");
            }
        }

        if (close.toLowerCase() !== "closed") {
            try {
                DATE_CLOSING = chrono.parseDate(close + " " + day.toDateString());

                if (DATE_CLOSING.getHours() < DATE_OPENING.getHours()) {
                    DATE_CLOSING = moment(DATE_CLOSING).add(1, "days").toDate();
                }
            } catch (e) {
                return alert("We are having trouble parsing one of your times. Please make sure it's a time using the " +
                    "format 12:00 PM or if the shop is closed have the time be CLOSED for both open and closed");
            }
        }

        if (DATE_OPENING !== null && DATE_CLOSING !== null) {
            DATE_OPENING = DATE_OPENING.getTime();
            DATE_CLOSING = DATE_CLOSING.getTime();
        }

        let {closings} = this.props.partner;
        request("partner/closing", "POST", {
            CLOSING_DATE: moment(day).add(60, "seconds").toDate().getTime(),
            DATE_CLOSING,
            DATE_OPENING
        }).then((id) => {
            closings.push({
                ID: id,
                CLOSING_DATE: moment(day).add(60, "seconds").toDate().getTime(),
                DATE_CLOSING,
                DATE_OPENING
            });

            this.setState({close: "", open: ""});
            this.props.updateClosings(closings);
        });
    }

    render() {
        let {hours, openOffset, closeOffset, day, open, close} = this.state;
        let {closings} = this.props.partner;

        if (!closings) {
            closings = [];
        }

        return (
            <div className="col-md-9">
                <div className="g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <div className="g-pa-0">
                        <div className="media">
                            <div className="d-flex align-self-center">
                                <h1 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                    Hours
                                </h1>
                            </div>

                            <div className="media-body align-self-center text-right">
                                <CorkButton onClick={this.save.bind(this)}>
                                    Save Changes
                                </CorkButton>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <Double label="Sunday" start={hours.SUNDAY_OPEN} end={hours.SUNDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, SUNDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, SUNDAY_CLOSE: txt}})}
                    />

                    <Double label="Monday" start={hours.MONDAY_OPEN} end={hours.MONDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, MONDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, MONDAY_CLOSE: txt}})}
                    />
                    <Double label="Tuesday" start={hours.TUESDAY_OPEN} end={hours.TUESDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, TUESDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, TUESDAY_CLOSE: txt}})}
                    />
                    <Double label="Wednesday" start={hours.WEDNESDAY_OPEN} end={hours.WEDNESDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, WEDNESDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, WEDNESDAY_CLOSE: txt}})}
                    />
                    <Double label="Thursday" start={hours.THURSDAY_OPEN} end={hours.THURSDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, THURSDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, THURSDAY_CLOSE: txt}})}
                    />
                    <Double label="Friday" start={hours.FRIDAY_OPEN} end={hours.FRIDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, FRIDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, FRIDAY_CLOSE: txt}})}
                    />
                    <Double label="Saturday" start={hours.SATURDAY_OPEN} end={hours.SATURDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, SATURDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, SATURDAY_CLOSE: txt}})}
                    />

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                        Mobile Ordering Offsets
                    </h2>

                    <div style={{marginTop: 8, marginBottom: 24}}>
                        Limit online and app based orders by setting time restrictions.
                    </div>

                    <div className="row g-mb-20">
                        <div className="col-md-3 align-self-center g-mb-5 g-mb-0--md">
                            <label className="mb-0" style={{fontWeight: "bold"}}>Rules</label>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group g-pos-rel mb-0">
                                <Select menuPortalTarget={document.body} styles={{
                                    menuPortal: base => ({...base, zIndex: 99999}),
                                    menuList: () => ({maxHeight: 150, overflowY: "scroll"})
                                }} options={MOBILE_OPEN_OFFSETS} value={openOffset}
                                        onChange={(openOffset) => this.setState({openOffset})}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group g-pos-rel mb-0">
                                <Select menuPortalTarget={document.body} styles={{
                                    menuPortal: base => ({...base, zIndex: 99999}),
                                    menuList: () => ({maxHeight: 150, overflowY: "scroll"})
                                }} options={MOBILE_CLOSE_OFFSETS} value={closeOffset}
                                        onChange={(closeOffset) => this.setState({closeOffset})}
                                />
                            </div>
                        </div>
                    </div>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                        Custom Hours
                    </h2>

                    <div style={{marginTop: 8, marginBottom: 24}}>
                        Select a date custom hours on special occasions. The ordering
                        offsets above will still take affect.
                    </div>

                    <div className="row g-mb-20">

                        <div className="col-md-3">
                            <div className="form-group g-pos-rel mb-0">
                                <DayPickerInput
                                    ref={(e) => this.date = e}
                                    inputProps={{
                                        className: "form-control form-control-md g-brd-gray-light-v7 g-brd-primary--focus g-brd-red--error g-rounded-4 g-px-20 g-py-12"
                                    }}
                                    value={day}
                                    onDayChange={(day) => {
                                        this.setState({day});
                                    }}
                                    dayPickerProps={{
                                        selectedDays: day
                                    }}/>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group g-pos-rel mb-0">
                                <input
                                    className="form-control h-150 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                    type="text" value={open}
                                    onChange={(e) => this.setState({open: e.target.value})}
                                    placeholder="Open Time (or CLOSED)"/>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group g-pos-rel mb-0">
                                <input
                                    className="form-control h-150 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                    type="text" value={close}
                                    onChange={(e) => this.setState({close: e.target.value})}
                                    placeholder="Close Time (or CLOSED)"/>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="form-group g-pos-rel mb-0">
                                <CorkButton onClick={this.addClosing.bind(this)}>
                                    Add
                                </CorkButton>
                            </div>
                        </div>
                    </div>

                    <div className="row g-mb-20">
                        <div className="col-md-2">
                            <label className="mb-0" style={{fontWeight: "bold"}}>
                            </label>
                        </div>


                        <div className="col-md-2"/>
                    </div>

                    {closings.map((item) => {
                        return <CustomHours item={item} {...this.props} />
                    })}
                </div>
            </div>
        )
    }
}

class CustomHours extends Component {
    state = {DATE_OPENING: null, DATE_CLOSING: null};

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let {item} = this.props;

        this.setState({
            ...item,
            DATE_OPENING: item.DATE_OPENING === null ? "closed" : moment(item.DATE_OPENING).format("hh:mm a"),
            DATE_CLOSING: item.DATE_CLOSING === null ? "closed" : moment(item.DATE_CLOSING).format("hh:mm a")
        });
    }

    save() {
        let day = new Date(this.state.CLOSING_DATE);
        if (this.state.DATE_OPENING.trim() === "" || this.state.DATE_CLOSING.trim() === "") {
            return alert("Please fill out every input with either a time or CLOSED");
        }

        let DATE_OPENING = this.state.DATE_OPENING, DATE_CLOSING = this.state.DATE_CLOSING;
        if (this.state.DATE_OPENING.toLowerCase() !== "closed") {
            try {
                DATE_OPENING = chrono.parseDate(this.state.DATE_OPENING + " " + day.toDateString());
            } catch (e) {
                return alert("We are having trouble parsing one of your times. Please make sure it's a time using the " +
                    "format 12:00 PM or if the shop is closed have the time be CLOSED for both open and closed");
            }
        }

        if (this.state.DATE_CLOSING.toLowerCase() !== "closed") {
            try {
                DATE_CLOSING = chrono.parseDate(this.state.DATE_CLOSING + " " + day.toDateString());

                if (DATE_CLOSING.getHours() < DATE_OPENING.getHours()) {
                    DATE_CLOSING = moment(DATE_CLOSING).add(1, "days").toDate();
                }
            } catch (e) {
                return alert("We are having trouble parsing one of your times. Please make sure it's a time using the " +
                    "format 12:00 PM or if the shop is closed have the time be CLOSED for both open and closed");
            }
        }

        if (DATE_OPENING !== null && DATE_CLOSING !== null) {
            DATE_OPENING = DATE_OPENING.getTime();
            DATE_CLOSING = DATE_CLOSING.getTime();
        }

        let {closings} = this.props.partner;
        request("partner/closing/" + this.props.item.ID, "PATCH", {
            DATE_CLOSING,
            DATE_OPENING
        }).then(() => {
            let index = closings.findIndex((item) => item.ID === this.props.item.ID);

            closings.splice(index, 1, {
                ...this.props.item,
                DATE_CLOSING,
                DATE_OPENING
            });

            alert("Hours Updated");
            this.props.updateClosings(closings);
        });
    }

    remove() {
        if (!window.confirm("Are you sure you want to remove this custom hours?")) {
            return;
        }

        let {closings} = this.props.partner;
        request("partner/closing/" + this.props.item.ID, "DELETE", {}).then(() => {
            let index = closings.findIndex((item) => item.ID === this.props.item.ID);

            closings.splice(index, 1);
            this.props.updateClosings(closings);
        });
    }

    render() {
        let {item} = this.props;
        let {DATE_CLOSING, DATE_OPENING} = this.state;

        return (
            <div className="row g-mb-20">
                <div className="col-md-2 align-self-center g-mb-5 g-mb-0--md">
                    <label className="mb-0" style={{fontWeight: "bold"}}>
                        {moment(item.CLOSING_DATE).format("dddd - MM/DD/YY")}
                    </label>
                </div>

                <div className="col-md-4">
                    <div className="form-group g-pos-rel mb-0">
                        <input
                            className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                            type="text" value={DATE_OPENING}
                            onChange={(e) => this.setState({DATE_OPENING: e.target.value})}
                            placeholder="Open Time (or CLOSED)"/>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="form-group g-pos-rel mb-0">
                        <input
                            className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                            type="text" value={DATE_CLOSING}
                            onChange={(e) => this.setState({DATE_CLOSING: e.target.value})}
                            placeholder="Open Time (or CLOSED)"/>
                    </div>
                </div>

                <div className="col-md-2"
                     style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "space-evenly"}}>
                    <i className="fa fa-check show-cursor" onClick={this.save.bind(this)}/>
                    <i className="fa fa-trash show-cursor" onClick={this.remove.bind(this)}/>
                </div>
            </div>
        );
    }
}

export default HoursTab;