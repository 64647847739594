import React, {Component} from 'react'
import {Switch, Route, Link} from 'react-router-dom'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'
import SecondaryNavItem from "../../modules/SecondaryNavItem";
import GeneralTab from "./tabs/GeneralTab";
import TransactionTab from "./tabs/TransactionTab";
import ItemsTab from "./tabs/ItemsTab";
import {request} from "../../util/Util";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class OrderScreen extends Component {
    state = {loading: true, order: {}};

    componentDidMount(): void {
        const {ID} = this.props.match.params;

        request("order/" + ID, "GET", null).then((order) => {
            this.setState({order, loading: false})
        }).catch(() => {
            alert("Internal Error :(");
        })
    }

    render() {
        const {ID} = this.props.match.params;
        const {loading, order} = this.state;

        if (loading) {
            return (
                <div>
                    loading
                </div>
            )
        }

        return (
            <div>
                <div className="g-hidden-sm-down g-bg-gray-light-v8 g-pa-20">
                    <ul className="u-list-inline g-color-gray-dark-v6">
                        <li className="list-inline-item g-mr-10">
                            <Link className="u-link-v5 g-color-gray-dark-v6 g-color-secondary--hover g-valign-middle"
                               to="/orders">
                                Orders
                            </Link>
                            <i className="hs-admin-angle-right g-font-size-12 g-color-gray-light-v6 g-valign-middle g-ml-10"/>
                        </li>
                        <li className="list-inline-item g-mr-10">
                            <Link className="u-link-v5 g-color-gray-dark-v6 g-color-secondary--hover g-valign-middle"
                               to={`/order/${order.ID}`}>
                                Order {order.ID}
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="g-pa-20">
                    <div className="row">
                        <div className="col-md-3 g-mb-30 g-mb-0--md">
                            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                <section className="text-center g-mb-30 g-mb-50--md">
                                    <br/>

                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        {order.NAME}
                                    </h3>

                                    <br/>

                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        Order #{order.ID}
                                    </h3>
                                </section>

                                <section>
                                    <ul className="list-unstyled mb-0">
                                        <SecondaryNavItem icon="fa fa-info" {...this.props}
                                                          url={"/order/" + ID} name="General Information"/>
                                        <SecondaryNavItem icon="fa fa-coffee" {...this.props}
                                                          url={"/order/" + ID + "/items"} name="Items"/>
                                        <SecondaryNavItem icon="fa fa-exchange-alt" {...this.props}
                                                          url={"/order/" + ID + "/transactions"} name="Transactions"/>
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <Switch>
                            <Route exact path='/order/:ID'
                                   component={() => <GeneralTab order={order} {...this.props} />}/>
                            <Route exact path='/order/:ID/items'
                                   component={() => <ItemsTab order={order} {...this.props} />}/>
                            <Route exact path='/order/:ID/transactions'
                                   component={() => <TransactionTab order={order} {...this.props} />}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderScreen));