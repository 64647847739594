import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import FormTextBox from "../../../modules/FormTextBox";
import {inverseString, request} from "../../../util/Util";
import Select from "react-select";
import FormCheckbox from "../../../modules/FormCheckbox";

let moment = require("moment");

class TicketTab extends Component {
    state = {settings: {}};

    componentWillMount() {
        this.setState({settings: JSON.parse(JSON.stringify(this.props.partner.settings))});
    }

    save() {
        let {settings} = this.state;

        request("settings", "PATCH", {SETTINGS: settings}).then(() => {
            this.props.updateSettings(settings);

            alert("Settings Saved");
        }).catch(() => {
            alert("ERROR");
        })
    }

    render() {
        let {settings} = this.state;

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            General information
                        </h2>
                    </header>

                    <FormCheckbox label="Auto Complete Register Orders"
                                  tooltip="If enabled register orders will not be shown on the ticket screen."
                                  onChange={() => {
                                      this.setState({
                                          settings: {
                                              ...settings,
                                              AUTO_COMPLETE_REGISTER_ORDERS: inverseString(settings.AUTO_COMPLETE_REGISTER_ORDERS)
                                          }
                                      })
                                  }} value={settings.AUTO_COMPLETE_REGISTER_ORDERS === "1"}/>
                    <FormCheckbox label="Auto Complete Kiosk Orders"
                                  tooltip="If enabled kiosk orders will not be shown on the ticket screen."
                                  onChange={() => {
                                      this.setState({
                                          settings: {
                                              ...settings,
                                              AUTO_COMPLETE_KIOSK_ORDERS: inverseString(settings.AUTO_COMPLETE_KIOSK_ORDERS)
                                          }
                                      })
                                  }} value={settings.AUTO_COMPLETE_KIOSK_ORDERS === "1"}/>
                    <FormCheckbox label="Auto Complete Mobile Orders"
                                  tooltip="If enabled mobile orders will not be shown on the ticket screen"
                                  onChange={() => {
                                      this.setState({
                                          settings: {
                                              ...settings,
                                              AUTO_COMPLETE_MOBILE_ORDERS: inverseString(settings.AUTO_COMPLETE_MOBILE_ORDERS)
                                          }
                                      })
                                  }} value={settings.AUTO_COMPLETE_MOBILE_ORDERS === "1"}/>
                    <FormCheckbox label="Auto Complete Web Orders"
                                  tooltip="If enabled web orders will not be shown on the ticket screen"
                                  onChange={() => {
                                      this.setState({
                                          settings: {
                                              ...settings,
                                              AUTO_COMPLETE_WEB_ORDERS: inverseString(settings.AUTO_COMPLETE_WEB_ORDERS)
                                          }
                                      })
                                  }} value={settings.AUTO_COMPLETE_WEB_ORDERS === "1"}/>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <div>
                        <button style={{backgroundColor: "#4ACE9C", color: "white"}} onClick={this.save.bind(this)}
                                className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10">
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default TicketTab;