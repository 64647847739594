import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import FormTextBox from "../../../modules/FormTextBox";
import {request} from "../../../util/Util";
import Select from "react-select";
import TaskModal from "../modules/TaskModal";
import {FancySelect} from "corky";
import CorkButton from "../../../modules/CorkButton";

let moment = require("moment");

const TIME_END = 1000 * 60 * 60 * 24;

class GeneralTab extends Component {
    state = {checklist: {}, hours: [], weeks: [], lines: []};

    componentWillMount() {
        let {checklist} = this.props;

        let start = moment().startOf("day").toDate().getTime();
        let offset = new Date().getTimezoneOffset() * 60 * 1000;

        this.setState({
            weeks: checklist.WEEKLY.split("|"),
            checklist: JSON.parse(JSON.stringify(checklist)),
            lines: JSON.parse(JSON.stringify(checklist.LINES)),
            hours: checklist.HOURLY.split("|").map((item) => ({
                label: moment(start + parseInt(item) - offset).format("hh:mm A"), value: item
            }))
        });
    }

    getSelectOptions() {
        let options = [];
        let start = moment().startOf("day").toDate().getTime();
        let offset = new Date().getTimezoneOffset() * 60 * 1000;

        const startConst = start;
        while (start + (1000 * 60 * 15) <= startConst + TIME_END) {
            options.push({label: moment(start).format("hh:mm A"), value: start - startConst + offset});

            if (options.length > 94) {
                break;
            }

            start += 1000 * 60 * 15;
        }

        return options;
    }

    toggleDay(i) {
        let {weeks} = this.state;

        let index = weeks.indexOf(i);
        if (index === -1) {
            weeks.push(i);
        } else {
            weeks.splice(index, 1);
        }

        this.setState({weeks});
    }

    updateInfo(silent) {
        let {checklist, lines, weeks, hours} = this.state;

        request("checklists/" + checklist.ID, "PATCH", {
            ...checklist,
            NAME: checklist.NAME,
            DAYS: weeks.join("|"),
            TIMES: hours.map((item) => item.value).join("|"),
            LINES: lines,
            DURATION: checklist.DURATION
        }).then((lRows) => {
            let checklists = this.props.partner.checklists;
            checklists.splice(checklists.findIndex((item) => item.ID === checklist.ID), 1, {
                ...checklist,
                NAME: checklist.NAME,
                WEEKLY: weeks.join("|"),
                HOURLY: hours.map((item) => item.value).join("|"),
                DURATION: checklist.DURATION,
                LINES: lRows
            });

            if (!silent) {
                this.props.updateChecklists(checklists);
            }

            alert("Checklist Saved");
        }).catch((err) => {
            alert("Problem saving invoice");
        });
    }

    reorder() {

    }

    render() {
        let {checklist, lines, weeks, hours} = this.state;
        lines.sort((a, b) => (a.SEQ === 0 ? 1000000 : a.SEQ) - (b.SEQ === 0 ? 10000 : b.SEQ));

        return (
            <div className="col-md-9">
                <TaskModal ref={(e) => this.tasks = e} checklist={checklist} {...this.props}/>

                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <header>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                Checklist Info
                            </h2>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                            <FormItem name="Name" value={checklist.NAME}
                                      onChange={(txt) => this.setState({checklist: {...checklist, NAME: txt}})}/>
                            <FormItem name="Duration" value={checklist.DURATION}
                                      onChange={(txt) => this.setState({checklist: {...checklist, DURATION: txt}})}/>

                            <div className="row g-mb-20">
                                <div className="col-md-3 align-self-center g-mb-5 g-mb-0--md">
                                    <label className="mb-0">Days</label>
                                </div>

                                <div className="col-md-9 align-self-center">
                                    <div className="form-group g-pos-rel mb-0 has-success">
                                        <div className="col-md-9 align-self-center">
                                            <div className="row g-mx-minus-10">
                                                <div className="btn-group justified-content">
                                                    <label className="u-check">
                                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                                                               name="currency" checked={weeks.indexOf("1") !== -1}
                                                               onChange={this.toggleDay.bind(this, "1")}
                                                               type="checkbox"/>
                                                        <span
                                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked rounded-0 g-py-10">
                                                            Mon
                                                     </span>
                                                    </label>
                                                    <label className="u-check">
                                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                                                               name="currency" checked={weeks.indexOf("2") !== -1}
                                                               onChange={this.toggleDay.bind(this, "2")}
                                                               type="checkbox"/>
                                                        <span
                                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                                        Tue
                                                    </span>
                                                    </label>
                                                    <label className="u-check">
                                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                                                               name="currency" checked={weeks.indexOf("3") !== -1}
                                                               onChange={this.toggleDay.bind(this, "3")}
                                                               type="checkbox"/>
                                                        <span
                                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                                        Wed
                                                    </span>
                                                    </label>
                                                    <label className="u-check">
                                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                                                               name="currency" checked={weeks.indexOf("4") !== -1}
                                                               onChange={this.toggleDay.bind(this, "4")}
                                                               type="checkbox"/>
                                                        <span
                                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                                        Thu
                                                    </span>
                                                    </label>
                                                    <label className="u-check">
                                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                                                               name="currency" checked={weeks.indexOf("5") !== -1}
                                                               onChange={this.toggleDay.bind(this, "5")}
                                                               type="checkbox"/>
                                                        <span
                                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                                        Fri
                                                    </span>
                                                    </label>
                                                    <label className="u-check">
                                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                                                               name="currency" checked={weeks.indexOf("6") !== -1}
                                                               onChange={this.toggleDay.bind(this, "6")}
                                                               type="checkbox"/>
                                                        <span
                                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                                        Sat
                                                    </span>
                                                    </label>
                                                    <label className="u-check">
                                                        <input className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
                                                               name="currency" checked={weeks.indexOf("7") !== -1}
                                                               onChange={this.toggleDay.bind(this, "7")}
                                                               type="checkbox"/>
                                                        <span
                                                            className="btn btn-md btn-block u-btn-outline-lightgray g-bg-lightblue-v3--checked g-font-size-16 g-color-white--checked g-brd-left-none--md rounded-0 g-py-10">
                                                        Sun
                                                    </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row g-mb-20">
                                <div className="col-md-3 align-self-center g-mb-5 g-mb-0--md">
                                    <label className="mb-0">Start Times</label>
                                </div>
                                <div className="col-md-9 align-self-center">
                                    <div className="form-group g-pos-rel mb-0 has-success">
                                        <Select menuPortalTarget={document.body} styles={{
                                            menuPortal: base => ({...base, zIndex: 99999}),
                                            menuList: () => ({maxHeight: 150, overflowY: "scroll"})
                                        }}
                                                isMulti
                                                options={this.getSelectOptions()} value={hours}
                                                onChange={(hours) => this.setState({hours})}
                                        />
                                    </div>
                                </div>
                            </div>

                            <header style={{marginTop: 40}}>
                                <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                    Checklist Tasks
                                </h2>

                            </header>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                            {lines.map((line, i) => (
                                <Line line={line} updateLine={(line) => {
                                    lines.splice(i, 1, line);

                                    this.setState({lines});
                                }} removeLine={(line) => {
                                    lines.splice(i, 1);

                                    this.setState({lines});
                                }}/>
                            ))}

                            <a className="u-link-v5 d-flex align-items-center g-color-secondary g-ml-30 show-cursor"
                               onClick={() => this.setState({
                                   lines: [...lines, {
                                       NAME: "",
                                       DESCRIPTION: "",
                                       TYPE: 0,
                                       SEQ: lines.length === 0 ? 1 : lines[lines.length - 1].SEQ + 1
                                   }]
                               })}>
                                <span
                                    className="u-badge-v2--xl g-pos-rel g-transform-origin--top-left g-bg-lightblue-v3 g-font-size-10 g-color-white">
                                    <i className="hs-admin-plus g-absolute-centered"/>
                                </span>
                                <span className="g-ml-15">Add Task</span>
                            </a>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                            <div className="row">
                                <button style={{backgroundColor: "#4ACE9C", color: "white"}}
                                        onClick={() => this.updateInfo(false)}
                                        className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10">
                                    Save Changes
                                </button>

                                <button style={{borderColor: "#CCCCCC", color: "#808080", marginLeft: 10}}
                                        onClick={() => {
                                            this.tasks.open(lines)
                                        }}
                                        className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10">
                                    Reorder Tasks
                                </button>
                            </div>
                        </header>
                    </form>
                </div>
            </div>
        )
    }
}

class Line extends Component {
    render() {
        let {line} = this.props;

        return (
            <div className="row g-mb-20">
                <div className="col-md-3">
                    <div className="form-group g-pos-rel mb-0">
                        <input
                            className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                            type="text" placeholder="Instructions"
                            onChange={(e) => this.props.updateLine({...line, NAME: e.target.value})}
                            value={line.NAME}/>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="form-group g-pos-rel mb-0">
                        <input
                            className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                            type="text" placeholder="Instructions"
                            onChange={(e) => this.props.updateLine({...line, DESCRIPTION: e.target.value})}
                            value={line.DESCRIPTION}/>
                    </div>
                </div>
                <div className="col-md-1" style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                     onClick={() => this.props.removeLine()}>
                    <i style={{color: "red", fontSize: 26}} className="fa fa-times show-cursor"/>
                </div>
            </div>
        )
    }
}


export default GeneralTab;