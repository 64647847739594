import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import {COLORS, request} from "../../../util/Util";
import FormTextBox from "../../../modules/FormTextBox";
import Double from "../../../modules/Double";
import {addNotification, ALERT_WARNING} from "../../../util/NotificationUtil";
import CorkButton from "../../../modules/CorkButton";
import Fancy from "../../../modules/fancy/Fancy";
import {FancySelect, FancyText} from "corky";

let Moment = require("moment");
let chrono = require('chrono-node');

class GeneralTab extends Component {
    state = {
        location: {},
        info: {}
    };

    componentDidMount() {
        this.setState({
            location: {...this.props.partner.location},
            info: {...this.props.partner.info}
        });
    }

    updateInfo() {
        let {location, info} = this.state;

        request("location", "PATCH", {...location, ...info}, (err) => {
            if (err) {
                alert("Error saving location");
                return;
            }

            alert("Your location has been updated");
            this.props.updateLocation(location);
            this.props.updateInfo(info);
            console.dir(info);
        })
    }

    online() {
        request("online", "POST").then(() => {
            this.props.updateLocation({...this.props.partner.location, ENABLED: false});
            alert("You have gone online!");
        });
    }

    render() {
        let {location, info} = this.state;

        let Online = <div/>;
        if (this.props.partner.location.ENABLED === 0) {
            Online = (
                <CorkButton onClick={() => this.online()}>
                    Go Online
                </CorkButton>
            )
        }

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <div className="g-pa-0">
                            <div className="media">
                                <div className="d-flex align-self-center">
                                    <h1 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                        Information
                                    </h1>
                                </div>

                                <div className="media-body align-self-center text-right">
                                    <CorkButton onClick={this.updateInfo.bind(this)}>
                                        Save Changes
                                    </CorkButton>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <FormItem name="Business Name" placeholder="Ex: Bob's Coffee Shop, Pizza Emporium, etc." value={location.NAME}
                                  onChange={(val) => this.setState({location: {...location, NAME: val}})}/>
                        <FormItem name="Website" placeholder="Ex: google.com" value={location.WEBSITE}
                                  onChange={(val) => this.setState({location: {...location, WEBSITE: val}})}/>
                        <FormTextBox name="Description" placeholder="Ex: We are a fast service cafe and bakery serving up quality dishes at an affordable price." value={location.DESCRIPTION}
                                     onChange={(val) => this.setState({location: {...location, DESCRIPTION: val}})}/>
                        <FormTextBox name="Order Instructions" placeholder="Ex: Pickup your order at the end of the counter." value={location.INSTRUCTIONS}
                                     tooltip="The pickup instructions shown after checkout"
                                     onChange={(val) => this.setState({location: {...location, INSTRUCTIONS: val}})}/>
                        <FormItem name="Shop Image" value={location.LOGO} tooltip="Visit imgur.com, drag your desired photo onto the page, right click the image, select Copy Image Address, then paste the link here."
                                  onChange={(val) => this.setState({location: {...location, LOGO: val}})}/>
                        <FormItem name="Logo" value={location.ICON}
                                  tooltip="Visit imgur.com, drag your desired photo onto the page, right click the image, select Copy Image Address, then paste the link here."
                                  onChange={(val) => this.setState({location: {...location, ICON: val}})}/>
                        <FormItem name="Sales Tax Rate" value={location.TAX_RATE}
                                  tooltip="The rate that you tax your customers on all sales."
                                  placeholder="Ex: 5 (sets sales tax to 5%)"
                                  onChange={(val) => this.setState({location: {...location, TAX_RATE: val}})}/>
                        <FormItem name="Min Mobile Order" value={location.MIN_ORDER}
                                  tooltip="The minimum amount someone can spend on a mobile order. Must be entered in cents."
                                  placeholder="Ex: 500 (sets minimum mobile order to $5.00)"
                                  onChange={(val) => this.setState({location: {...location, MIN_ORDER: val}})}/>

                        <header style={{marginTop: 40}}>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                Business Contact Info
                            </h2>
                        </header>

                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>
                        <FormItem name="Email" value={info.EMAIL}
                                  onChange={(val) => this.setState({info: {...info, EMAIL: val}})}/>
                        <FormItem name="Phone" value={info.PHONE}
                                  onChange={(val) => this.setState({info: {...info, PHONE: val}})}/>

                        <header style={{marginTop: 40}}>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                Social Links
                            </h2>
                        </header>

                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>
                        <FormItem name="Facebook" value={info.FACEBOOK}
                                  onChange={(val) => this.setState({info: {...info, FACEBOOK: val}})}/>
                        <FormItem name="Twitter" value={info.TWITTER}
                                  onChange={(val) => this.setState({info: {...info, TWITTER: val}})}/>
                        <FormItem name="Instagram" value={info.INSTAGRAM}
                                  onChange={(val) => this.setState({info: {...info, INSTAGRAM: val}})}/>

                        <header style={{marginTop: 40}}>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                Location
                            </h2>
                        </header>

                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>
                        <FormItem name="Address" value={location.ADDRESS}
                                  onChange={(val) => this.setState({location: {...location, ADDRESS: val}})}/>
                        <FormItem name="City" value={location.CITY}
                                  onChange={(val) => this.setState({location: {...location, CITY: val}})}/>
                        <FormItem name="Zipcode" value={location.ZIPCODE}
                                  onChange={(val) => this.setState({location: {...location, ZIPCODE: val}})}/>
                        <FormItem name="State" value={location.STATE}
                                  onChange={(val) => this.setState({location: {...location, STATE: val}})}/>
                        <FormItem name="Latitude" value={location.LATITUDE}
                                  onChange={(val) => this.setState({location: {...location, LATITUDE: val}})}/>
                        <FormItem name="Longitude" value={location.LONGITUDE}
                                  onChange={(val) => this.setState({location: {...location, LONGITUDE: val}})}/>

                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                        <div>
                            <CorkButton onClick={this.updateInfo.bind(this)}>
                                Save Changes
                            </CorkButton>
                            {Online}
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default GeneralTab;