import React, {Component} from 'react'
import Report from "./module/Report";
import {toDollars} from "../../util/Util";
import {CorkTable} from "corky";
import {PLATFORMS, TRANSACTION_TYPES} from "../../util/Constants";
import {Chart} from "react-charts";
import moment from "moment";
import DailyChart from "./module/DailyChart";
import {bindActionCreators} from "redux";
import Actions from "../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ReportCard from "./module/ReportCard";
import DayChart from "./module/DayChart";
import HourChart from "./module/HourChart";
import {Reporting} from "drip-drop"

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class SalesSummary extends Report {
    componentDidMount() {
        let {start, end, locations} = this.props.partner.reporting;

        super.updateData(start, end, locations)
    }

    getColumns(data) {
        let {gross, tips, collected, refunds, processing} = Reporting.Helpers.calculateSummary(data);
        let refundAmount = data.filter((item) => item.REFUND_ID !== null);

        return {
            PAYMENTS: data.length,
            REFUNDS: refundAmount.length,
            PAYMENT_AMOUNT: "$" + toDollars(gross),
            REFUND_AMOUNT: "$" + toDollars(refunds),
            TIPS: "$" + toDollars(tips),
            TOTAL_COLLECTED: "$" + toDollars(collected),
            FEES: "$" + toDollars(processing)
        };
    }

    renderPayments() {
        let {orders: data} = this.props.partner.reporting.data;
        let {otherPayments} = this.props.partner;

        let cash = data.filter((item) => item.TRANSACTION_TYPE === TRANSACTION_TYPES.CASH);
        let web = data.filter((item) => item.PLATFORM === PLATFORMS.WEB);
        let terminal = data.filter((item) => item.PLATFORM === PLATFORMS.REGISTER && item.TRANSACTION_TYPE === TRANSACTION_TYPES.CARD);
        let mobile = data.filter((item) => item.PLATFORM === PLATFORMS.MOBILE);
        let gifts = data.filter((item) => item.TRANSACTION_TYPE === TRANSACTION_TYPES.GIFT_CARD);
        let other = data.filter((item) => item.TRANSACTION_TYPE === TRANSACTION_TYPES.OTHER);
        let splits = data.filter((item) => item.TRANSACTION_TYPE === TRANSACTION_TYPES.SPLIT);

        let leftOther = {ID: 7, METHOD: "Other", ...this.getColumns(other)};

        return (
            <CorkTable noPadding={true} key={data} headerMode="download" downloadText="Download" header={{
                METHOD: {
                    NAME: "Method",
                    WIDTH: 20,
                    SORT: "ID"
                },
                PAYMENTS: {
                    NAME: "Payments",
                    WIDTH: 10
                },
                REFUNDS: {
                    NAME: "Refunds",
                    WIDTH: 10
                },
                PAYMENT_AMOUNT: {
                    NAME: "Payment Amount",
                    WIDTH: 12.5
                },
                REFUND_AMOUNT: {
                    NAME: "Refund Amount",
                    WIDTH: 12.5
                },
                TIPS: {
                    NAME: "Tips",
                    WIDTH: 12.5
                },
                TOTAL_COLLECTED: {
                    NAME: "Total Collected",
                    WIDTH: 12.5
                },
                FEES: {
                    NAME: "Fees",
                    WIDTH: 12.5
                }
            }} sort="METHOD" desc={false} title="Sales Breakdown by Method" data={[
                {ID: 1, METHOD: "Cash", ...this.getColumns(cash)},
                {ID: 2, METHOD: "Card in Store", ...this.getColumns(terminal)},
                {ID: 3, METHOD: "Card on Web", ...this.getColumns(web)},
                {ID: 4, METHOD: "Mobile", ...this.getColumns(mobile)},
                {ID: 5, METHOD: "Gift Card", ...this.getColumns(gifts)},
                ...(otherPayments.length > 0 ? otherPayments.map((item) => ({
                    ID: 10 + item.ID,
                    METHOD: item.NAME, ...this.getColumns(other.filter((other) => other.OTHER_ID === item.ID))
                })) : [leftOther]),
                {ID: 100000000, METHOD: "Total", ...this.getColumns(data)},
            ]} ignoreRowParams={true} hidePaging={true}/>
        )
    }

    render() {
        let {orders: data} = this.props.partner.reporting.data;
        let {gross, tips, collected: total, discounts, net, refunds, taxes, processing} = Reporting.Helpers.calculateSummary(data);

        return (
            <div>
                {super.render()}

                <div className="row g-pa-20">
                    <ReportCard
                        content={"$" + toDollars(data.length === 0 ? 0 : (total / data.filter((item) => item.REFUND_ID === null).length))}
                        label="Average Ticket" description="Total Collected / Tickets"/>

                    <ReportCard label="Tickets" description="Qty. of Non-Refunded Transactions"
                                content={data.filter((item) => item.REFUND_ID === null).length}/>

                    <ReportCard label="Net Sales" content={"$" + toDollars(net)}
                                description="Net sales"/>
                    <ReportCard label="Tips"
                                content={"$" + toDollars(data.reduce((accum, item) => accum + item.TIP_AMOUNT, 0))}
                                description="Total tips during timespan"/>
                </div>

                <DailyChart title="Tickets & Sales Per Day" label="Tickets" fields={[
                    {
                        label: "Sales", calc: (items) => parseFloat(items.reduce((accum, item) => {
                            return accum + (item.TOTAL / 100)
                        }, 0.0))
                    },
                    {label: "Tickets", calc: (items) => parseFloat(items.length)}
                ]} data={data} {...this.props} />

                <div className="row">
                    <div className="col-md-4">
                        <DayChart title="Orders by Day" label="Tickets" data={data} {...this.props} />
                    </div>

                    <div className="col-md-8">
                        <HourChart title="Orders by Hours" label="Tickets" data={data} {...this.props} />
                    </div>
                </div>

                <div className="row col-xl-12">
                    <div className="col-md-4">
                        <CorkTable noPadding={true} key={data} headerMode="download" header={{
                            KEY: {
                                NAME: "Info",
                                WIDTH: 70,
                                TYPE: "String",
                                SORT: "ID"
                            },
                            VALUE: {
                                NAME: "$",
                                WIDTH: 30
                            }
                        }} sort="KEY" downloadText="Download" title="Sales Breakdown" desc={false} data={[
                            {ID: 1, KEY: "Gross Sales", VALUE: "$" + toDollars(gross)},
                            {ID: 2, KEY: "Refunds", VALUE: "$" + toDollars(refunds)},
                            {ID: 3, KEY: "Discounts", VALUE: "$" + toDollars(discounts)},
                            {ID: 4, KEY: "Net Sales", VALUE: "$" + toDollars(net)},
                            {ID: 5, KEY: "Taxes", VALUE: "$" + toDollars(taxes)},
                            {ID: 5, KEY: "Tips", VALUE: "$" + toDollars(tips)},
                            {ID: 5, KEY: "Processing", VALUE: "$" + toDollars(processing)},
                            {ID: 5, KEY: "Total Sales", VALUE: "$" + toDollars(total)}
                        ]} disableHeader={true} disableSort={true} ignoreRowParams={true} hidePaging={true}/>
                    </div>

                    <div className="col-md-8">
                        {this.renderPayments()}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SalesSummary));