import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import FormTextBox from "../../../modules/FormTextBox";
import {inverseString, request} from "../../../util/Util";
import Select from "react-select";
import FormCheckbox from "../../../modules/FormCheckbox";

class ReportsTab extends Component {
    state = {settings: {}};

    componentWillMount() {
        this.setState({settings: JSON.parse(JSON.stringify(this.props.partner.settings))});
    }

    save() {
        let {settings} = this.state;

        request("settings", "PATCH", {SETTINGS: settings}).then(() => {
            this.props.updateSettings(settings);

            alert("Settings Saved");
        }).catch(() => {
            alert("ERROR");
        })
    }

    render() {
        let {settings} = this.state;

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            General information
                        </h2>
                    </header>

                    <FormItem name="Report Open Offset (in minutes)"
                              tooltip="Minutes from 12am the reports should start from (-60 for 11am last day)"
                              onChange={(val) => {
                                  this.setState({
                                      settings: {
                                          ...settings,
                                          REPORT_OFFSET_OPEN: val
                                      }
                                  })
                              }} value={settings.REPORT_OFFSET_OPEN}/>

                    <FormItem name="Report Close Offset (in minutes)"
                              tooltip="Minutes from 12am the reports should end at (120 for 2am next day)"
                              onChange={(val) => {
                                  this.setState({
                                      settings: {
                                          ...settings,
                                          REPORT_OFFSET_CLOSE: val
                                      }
                                  })
                              }} value={settings.REPORT_OFFSET_CLOSE}/>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <div>
                        <button style={{backgroundColor: "#4ACE9C", color: "white"}} onClick={this.save.bind(this)}
                                className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10">
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportsTab;