import React, {Component} from 'react'
import Report from "./module/Report";
import {toDollars} from "../../util/Util";
import {CorkTable} from "corky";
import {PLATFORMS, TRANSACTION_TYPES} from "../../util/Constants";

class TicketReport extends Report {
    constructor(props) {
        super(props);

        this.cards = [
            {
                LABEL: "Sales",
                DESCRIPTION: "Total volume",
                CALC: (items) => "$" + toDollars(items.reduce((accum, item) => accum + item.TOTAL + (item.TIP ? item.TIP.AMOUNT : 0), 0))
            },
            {
                LABEL: "Transactions",
                DESCRIPTION: "Number of transactions",
                CALC: (items) => items.length
            },
            {
                LABEL: "Average Ticket",
                DESCRIPTION: "Sales / Transactions",
                CALC: (items) => "$" + toDollars(items.length === 0 ? 0 : Math.floor(items.reduce((accum, item) => accum + item.TOTAL, 0) / items.length))
            }
        ];

        this.sort = "DATE_SENT";
        this.name = "Tickets";
        this.downloadUrl = "report/summary/sales";
        this.downloadName = "Drip_Sales_Summary.csv";
        this.valCal = (item) => 1;
        this.perCalc = (item) => item.length;
    }

    render() {
        let data = this.getFilterData();
        let {tips} = this.getData();

        let gross = data.reduce((accum, item) => {
            return accum + item.SUBTOTAL
        }, 0);

        let net = gross;
        let refunds = data.filter((item) => item.REFUND_ID !== null).reduce((accum, item) => {
            return accum + item.SUBTOTAL
        }, 0);

        let discounts = data.reduce((accum, item) => accum + item.DISCOUNTS, 0);

        net -= refunds + discounts;

        let total = net;

        let {start, end} = this.props.partner.reporting;

        tips = tips.filter(this.getFilterFunction()).reduce((accum, item) => {
            return accum + item.AMOUNT
        }, 0);

        total += tips;

        let taxes = data.reduce((accum, item) => {
            return accum + item.TAXES
        }, 0);

        total += taxes;

        let processing = data.reduce((accum, item) => {
            return accum + item.PROCESSING
        }, 0);

        total -= processing;

        let payments = 0;
        let cash = data.filter((item) => item.TRANSACTION_TYPE === TRANSACTION_TYPES.CASH).reduce((accum, item) => accum + item.SUBTOTAL, 0);
        let web = data.filter((item) => item.PLATFORM === PLATFORMS.WEB).reduce((accum, item) => accum + item.SUBTOTAL, 0);
        let terminal = data.filter((item) => item.PLATFORM === PLATFORMS.REGISTER && item.TRANSACTION_TYPE !== TRANSACTION_TYPES.CASH).reduce((accum, item) => accum + item.SUBTOTAL, 0);
        let mobile = data.filter((item) => item.PLATFORM === PLATFORMS.MOBILE).reduce((accum, item) => accum + item.SUBTOTAL, 0);
        let gifts = data.filter((item) => item.TRANSACTION_TYPE === TRANSACTION_TYPES.GIFT_CARD).reduce((accum, item) => accum + item.SUBTOTAL, 0);

        payments += cash + web + terminal + mobile + gifts;

        return (
            <div>
                {super.render()}

                <div className="row">
                    <CorkTable key={data} headerMode="download" header={{
                        KEY: {
                            NAME: "Info",
                            WIDTH: 50,
                            TYPE: "String",
                            SORT: "ID"
                        },
                        VALUE: {
                            NAME: "$",
                            WIDTH: 50
                        }
                    }} sort="KEY" data={[
                        {ID: 1, KEY: "Gross Sales", VALUE: "$" + toDollars(gross)},
                        {ID: 2, KEY: "Refunds", VALUE: "$" + toDollars(refunds)},
                        {ID: 3, KEY: "Discounts", VALUE: "$" + toDollars(discounts)},
                        {ID: 4, KEY: "Net Sales", VALUE: "$" + toDollars(net)},
                        {ID: 5, KEY: "Taxes", VALUE: "$" + toDollars(taxes)},
                        {ID: 5, KEY: "Tips", VALUE: "$" + toDollars(tips)},
                        {ID: 5, KEY: "Processing", VALUE: "$" + toDollars(processing)},
                        {ID: 5, KEY: "Total Sales", VALUE: "$" + toDollars(total)}
                    ]} desc={false} title="Sales Breakdown"/>
                </div>

                <div className="row">
                    <CorkTable key={data} headerMode="download" header={{
                        KEY: {
                            NAME: "Info",
                            WIDTH: 50,
                            TYPE: "String",
                            SORT: "ID"
                        },
                        VALUE: {
                            NAME: "$",
                            WIDTH: 50
                        }
                    }} sort="KEY" data={[
                        {ID: 1, KEY: "Cash", VALUE: "$" + toDollars(cash)},
                        {ID: 2, KEY: "Card in Store", VALUE: "$" + toDollars(terminal)},
                        {ID: 3, KEY: "Card on Web", VALUE: "$" + toDollars(web)},
                        {ID: 4, KEY: "Mobile", VALUE: "$" + toDollars(mobile)},
                        {ID: 5, KEY: "Gift Card", VALUE: "$" + toDollars(gifts)},
                        {ID: 6, KEY: "Total", VALUE: "$" + toDollars(payments)}
                    ]} desc={false} title="Payments"/>
                </div>
            </div>
        )
    }

    componentWillMount() {
        let {orders, tips} = this.getData();

        let data = orders.sort((a, b) => a.DATE_SENT - b.DATE_SENT);
        let ids = data.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        for (let tip of tips) {
            ids[tip.ORDER_ID].TIP = tip;
        }

        this.setState({data});
    }
}

export default TicketReport;